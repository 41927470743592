.square-container {
    position: absolute;
    top: -0rem;
    z-index: 1;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .square {
    position: absolute;
    top: 0rem;
    left: 0;
    transform: translateX(-50vw);
    width: 50vw;
    height: 100%;
    background-color: #ffffff6b;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: left 5s linear, transform 5s linear;
  }
  .square-container.animate .square {
    left: calc(100% - 29.5vw);
    transform: translateX(-20vw) ;
  }

  .pulsating-rectangle {
    /* width: 200px; */
    /* height: 100px; */
    background-color: rgba(234, 247, 215, 0.774);
    animation: pulsate 2s ease-in-out infinite;
  }
  .btn-close {
    position: relative;
    top: -14px;
    left: -12px;
    float: left;
    font-size: 2rem;
  }
  @keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  