
.App {
  text-align: center;
}

.fab-network {
  position: aboslute;
  bottom: 0px;
}
.frame-presentation {
    overflow-y: hidden;
}

.languajes {
  border: 1px solid black;
}

/*  background */
.backgroud-img  {
  /* opacity:0.6; */
  height: 100vh;
}
.grayscale {
  filter: grayscale(100%);
  opacity: 0.5; /* Ajusta el valor de opacidad según tus necesidades */
}

/* ChatBOT */

.chat-history {
  display: grid;
  flex-flow: row;
  gap: 5px;
}

.message {
  border-radius: 10px;
  padding: 0 10px 0 10px;
}
.message-assistant {
  border: 1px solid blue;
}
.message-user {
  border: 1px solid red;
}




.burbuja {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: blue;
}


.hidden {
  height: 0px;
  opacity: 0;
  transition: opacity 5s ease;
}
.shower {
  width: 100%;
  opacity: 1;
  height: 100%;
  transition: opacity 5s ease;

}

.background-img {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100vw;
  transition: opacity 2s ease;
}
/* NETWORK */

.networks {

}
.icon-network {
  border: 2px solid black;
  height: 50px;
  width: 25px;
  border-radius: 2px;
  background-color: #fffdfdef;
}


.networks-open {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 55px 1fr;
    height: 15rem;
}
.network-urls {
  border: 1px solid gray;
  padding: 4px;
  width: 12rem;
  border-radius: 5px;
  background-color: #fffdfd;
}
.networks-close {
  display: none;
}
.networks section {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 5px;
}

.forTest {
  border: 1px solid black;
}
/* MENU */
.menu-open {

  display: block;
}
.menu {

  display: none;
}
/* swipper */

.swiper {
  width: 100%;
  height: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
}

@media (min-width: 640px) { 

 }

@media (min-width: 640px) {
  .swiper .swiper-slide {
    height: 40vh;
    overflow-y: auto;
    background: #fff;
    line-height: 300px;
  }
}

@media (min-width: 1024px) {
  .swiper .swiper-slide {
    height: 80vh;
    overflow-y: auto;
    background: #fff;
    line-height: 300px;
  }
 }
 /* --------------- */

.borderTop{
  border-top: 1px solid black;
}
.tags{
  width: 100%;
  display: flex;
  justify-content: center;
  text-shadow: 0 0 2px #fff;
}
.center-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate {
  display: inline-block;
  padding: 0 5px;
  vertical-align: 3px;
  font-size: 20px;
  font-weight: normal;
}

.animate.on {
  animation: fade 1.5s infinite forwards;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



/* pruebas con chat-gpt */

.paralelogramo {
  width: 100%;
  height: 100vh;
  background-color:#f1f1f100;
  position: absolute;
  max-width: 100%;
  top: 0;
  left: 0;
  overflow-x: clip;
}

.paralelogramo::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%; /* Ajuste aquí */
  width: 50%;
  height: 100%;
  max-width: 100%;
  background-color: #ffffff6e;
  transform: skewX(-30deg);
  transform-origin: left bottom ;
  transition: left 5s ease-in-out; /* Transición de 5 segundos */
}

.paralelogramo:hover::before {
  left: 0; /* Cuando se hace hover, se desplaza a la posición inicial */
}
